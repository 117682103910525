import { Link } from "react-router-dom";
import { CloseIcon, MenuIcon } from "../../assets/svg";
import {
  headerLogo,
  leftNavItems,
  logoItem,
  rightNavItems,
  mobileNavItems,
} from "../../router/navigation";
import {
  HamburgerMenu,
  HamburgerWrapper,
  HeaderLogo,
  MobileNav,
  Nav,
  Ul,
} from "../../utils/styles/GlobalStyles";
import { IconActionButton } from "../Buttons";

interface NavItem {
  path: string;
  title?: string | JSX.Element;
  logo?: JSX.Element;
}

interface HeaderProps {
  selected: string;
  setSelected: (path: string) => void;
}

const NavLinks = ({
  items,
  selected,
  setSelected,
}: {
  items: NavItem[];
  selected: string;
  setSelected: (path: string) => void;
}) => (
  <div className="spreaded-links">
    {items.map((item) => (
      <li key={item.path} onClick={() => setSelected(item.path)}>
        <Link
          to={item.path}
          className={selected === item.path ? "selected" : ""}
        >
          <>{item.title || item.logo}</>
        </Link>
      </li>
    ))}
  </div>
);

const LogoLink = ({ path, logo }: { path: string; logo: JSX.Element }) => (
  <div>
    <Link to={path}>{logo}</Link>
  </div>
);

export const Header = ({ selected, setSelected }: HeaderProps) => (
  <header className="App-header">
    <Nav>
      <NavLinks
        items={leftNavItems}
        selected={selected}
        setSelected={setSelected}
      />
      <LogoLink path={logoItem.path} logo={logoItem.logo as JSX.Element} />
      <NavLinks
        items={rightNavItems}
        selected={selected}
        setSelected={setSelected}
      />
    </Nav>
    <HeaderLogo>
      <img src={headerLogo} alt="headerLogo" />
    </HeaderLogo>
    <hr className="fancy-line" />
  </header>
);

export const MobileHeader = ({
  opened,
  setOpened,
  selected,
  setSelected,
}: HeaderProps & { opened: boolean; setOpened: (value: boolean) => void }) => (
  <MobileNav>
    {opened && (
      <HamburgerWrapper>
        <HamburgerMenu>
          <IconActionButton
            icon={<CloseIcon />}
            action={() => setOpened(false)}
            className="close-btn"
          />
          <nav>
            <Ul>
              {mobileNavItems.map((item) => (
                <li
                  key={item.path}
                  onClick={() => {
                    setOpened(false);
                    setSelected(item.path);
                  }}
                >
                  <Link
                    to={item.path}
                    className={selected === item.path ? "selected" : ""}
                  >
                    <>{item.title}</>
                  </Link>
                </li>
              ))}
            </Ul>
          </nav>
        </HamburgerMenu>
      </HamburgerWrapper>
    )}
    <IconActionButton
      className="menu-icon"
      icon={<MenuIcon />}
      action={() => setOpened(!opened)}
    />
  </MobileNav>
);
