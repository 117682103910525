import { lazy, Suspense } from "react";
import { Loading } from "../components/Loading/Loading";

const LazyShop = lazy(() => import("../pages/Shop/Shop"));

function ShopPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyShop />
    </Suspense>
  );
}

export default ShopPage;
