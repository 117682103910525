import React, { Suspense } from "react";
import { Loading } from "../components/Loading/Loading";

const LazyLoginPage = React.lazy(() => import("../pages/Login/Login"));

function LoginPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyLoginPage />
    </Suspense>
  );
}

export default LoginPage;
