import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./utils/styles/App.css";

import { HeaderMenu, Socials, WrapperApp } from "./utils/styles/GlobalStyles";
import Blog from "./loadable/Blog.loadable";
import Events from "./loadable/Events.loadable";
import Home from "./loadable/Home.loadable";
import Login from "./loadable/Login.loadable";
import Shop from "./loadable/Shop.loadable";
import Whoami from "./loadable/Contact.loadable";
import Yoga from "./loadable/ShoppingCart.loadable";
import { EnvelopIcon, FacebookIcon, InstagramIcon } from "./assets/svg";
import { IconButton } from "./components/Buttons";
import { useContext, useState } from "react";
import { AppContext } from "./context/AppContext";
import { Header, MobileHeader } from "./components/Header/Header";

function App() {
  const current = window.location.pathname;
  const { email, facebook, instagram } = useContext(AppContext);
  const [selected, setSelected] = useState(current);
  const [opened, setOpened] = useState(false);

  return (
    <div className="App fadeIn">
      <WrapperApp>
        <Router>
          <HeaderMenu>
            <Socials>
              <IconButton link={facebook} icon={<FacebookIcon />} />
              <IconButton link={instagram} icon={<InstagramIcon />} />
              <IconButton link={"mailto:" + email} icon={<EnvelopIcon />} />
            </Socials>
            <MobileHeader
              opened={opened}
              setOpened={setOpened}
              selected={selected}
              setSelected={setSelected}
            />
          </HeaderMenu>
          <Header selected={selected} setSelected={setSelected} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/yoga" exact component={Yoga} />
            <Route path="/events" exact component={Events} />
            {/* <Route path="/online" exact component={Online} /> */}
            <Route path="/shop" exact component={Shop} />
            <Route path="/blog" exact component={Blog} />
            <Route path="/qui-suis-je" exact component={Whoami} />
            <Route path="/login" exact component={Login} />
          </Switch>
        </Router>
      </WrapperApp>
    </div>
  );
}

export default App;
