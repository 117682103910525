import { ReactElement } from "react";

const BASE_URL = "https://stellastudio.be/assets/images";
export const headerLogo = `${BASE_URL}/header_logo.png`;

export interface NavItem {
  path: string;
  title?: string | ReactElement;
  logo?: ReactElement;
}

const Logo = () => (
  <img
    style={{ height: "85px", margin: "0 0 32px 0" }}
    alt="logo"
    src={headerLogo}
  />
);

export const leftNavItems: NavItem[] = [
  { path: "/", title: "Accueil" },
  { path: "/yoga", title: "Yoga" },
  { path: "/events", title: "Events" },
];

export const rightNavItems: NavItem[] = [
  { path: "/shop", title: "Boutique" },
  { path: "/blog", title: "Blog" },
  { path: "/qui-suis-je", title: "Qui suis-je" },
];

export const logoItem: NavItem = { path: "/", logo: <Logo /> };

export const navItems: NavItem[] = [
  ...leftNavItems,
  logoItem,
  ...rightNavItems,
];
export const mobileNavItems: NavItem[] = [...leftNavItems, ...rightNavItems];
