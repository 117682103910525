import React, { Suspense } from "react";
import { Loading } from "../components/Loading/Loading";

const LazyBlogPage = React.lazy(() => import("../pages/Blog/Blog"));
function BlogPage() {
  return (
    <Suspense fallback={<Loading />}>
      <LazyBlogPage />
    </Suspense>
  );
}

export default BlogPage;
